// TODO: Use npm packages
import 'bootstrap';
import 'jquery-blockui';
import 'jquery-migrate';
import 'magnific-popup';
import './legacy';

addEventListener('DOMContentLoaded', async () => {
    await import(/* webpackChunkName: "vendors-deferred" */ './deferredLegacy');

    if (document.querySelector('#html-editor')) {
        await import('../RedactorPlugin');
        await import('../HtmlEditor');
    }
});
